
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  private refreshing = false;
  private loading = false;
  private finished = false;
  private listData: any[] = [];
  private page = {
    pageSize: 10,
    pageNum: 1,
    dirId: "",
    deviceSN: "",
  };

  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.listData = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      this.page.deviceSN = this.$route.query.id as any;
      this.page.dirId = this.$store.state.project.projectinfoDetail.id;

      api.pageDriverRecordTower(this.page).then((res: any) => {
        this.listData = [...this.listData, ...res.data.records];
        this.page.pageNum++;
        this.loading = false;
        if (this.listData.length >= res.data.total) {
          this.finished = true;
        }
      });
    }, 1000);
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
}
